import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from "react";

import useApi from "@/hooks/useApi";
import useSubscribe from "./useSubscribe";
import useConnection from "./useConnection";
import messageListReducer from "@/reducers/messageListReducer";
import messageGroupReducer from "@/reducers/messageGroupReducer";

export default function usePastMessages(conversationId: number) {
  const cancel = useRef(false);
  const [messages, dispatch] = useReducer(messageListReducer, []);

  const [loadingMessages, setLoadingMessages] = React.useState(true);
  const [conversationFullyLoaded, setConversationFullyLoaded] = React.useState(
    false
  );
  const api = useApi();

  // Fetch messages on load

  const fetch = useCallback(() => {
    cancel.current = false;

    async function fetchMessages() {
      setConversationFullyLoaded(false);
      const response = await api.messaging.get(
        `Conversations/${conversationId}/Messages`
      );

      if (cancel.current === true) {
        return;
      }

      dispatch({ type: "SET_MESSAGES", messages: await response.json() });
      setLoadingMessages(false);
    }

    fetchMessages();

    return () => {
      cancel.current = true;
    };
  }, [api, conversationId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  // Exposed method to load more messages as the user scrolls up.

  const loadMoreMessages = useCallback(async () => {
    if (conversationFullyLoaded) {
      return;
    }

    const lastMessage = messages[0];

    if (!lastMessage) {
      return;
    }

    const response = await api.messaging.get(
      `Conversations/${conversationId}/Messages?lastMessageId=${lastMessage.id}`
    );

    const loadedMessages = await response.json();

    if (cancel.current === true) {
      return;
    }

    if (loadedMessages.length > 0) {
      dispatch({ type: "PREPEND_MESSAGES", messages: loadedMessages });
    } else {
      setConversationFullyLoaded(true);
    }
  }, [api, messages, conversationId, conversationFullyLoaded]);

  const groupedMessages = useMemo(() => messageGroupReducer(messages), [
    messages
  ]);

  return {
    messages,
    groupedMessages,
    loadingMessages,
    loadMoreMessages
  };
}
