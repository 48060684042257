import useTranslations from "@/hooks/useTranslations";
import React from "react";
import AddNoteModal from "../AddNoteModal";

interface Props {
  id?: number;
  onSubmit(note: string): void;
}

export default function AddNoteForm(props: Props) {
  const [text, setText] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const translation = useTranslations();

  return (
    <>
      {props.id == null && (
        <input
          type="text"
          placeholder={translation.key("add_note")}
          className="bg-cornflower-blue-50 w-full p-2 rounded-sm dark:bg-stone-600 dark:text-white"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && text.length > 0) {
              props.onSubmit(text);
              setText("");
            }
          }}
        />
      )}
      {props.id != null && (
        <div className="flex flew-row w-full items-center">
          <button
            className="flex ml-1 items-center hover:text-cornflower-blue-600 hover:bg-cornflower-blue-50"
            onClick={() => setShowModal(true)}
          >
            <i className="fa fa-plus px-1"></i>
            Add Note
          </button>
          {showModal && (
            <AddNoteModal
              conversationId={props.id}
              onClose={() => setShowModal(false)}
            />
          )}
        </div>
      )}
    </>
  );
}
