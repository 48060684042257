import { Note } from "@/types";
import { useCallback, useEffect, useState } from "react";
import useApi from "./useApi";
import useSubscribe from "./useSubscribe";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

export default function useConversationNotes(conversationId: number) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Note[]>([]);
  const api = useApi();
  const subscribe = useSubscribe();
  const toastAlert = useToastAlert();

  useEffect(() => {
    async function fetchNotes() {
      const resp = await api.messaging.get(
        `ConversationNotes/List?conversationId=${conversationId}`
      );

      if (resp.ok) {
        setData(await resp.json());
      } else {
        toastAlert.displayToast(
          Severity.Error,
          "An unexpected error occurred while loading conversation notes"
        );
        console.error("error loading conversation notes");
      }
      setLoading(false);
    }

    fetchNotes();
  }, [api.messaging, conversationId, toastAlert]);

  const add = useCallback(
    async (text: string) => {
      const resp = await api.messaging.post(`ConversationNotes`, {
        conversationId,
        text
      });

      if (!resp.ok) {
        toastAlert.displayToast(Severity.Error, "An unexpected error occurred");
      }
    },
    [api.messaging, conversationId, toastAlert]
  );

  useEffect(
    () =>
      subscribe("conversation-note-added", (message) => {
        if (message.conversationId === conversationId) {
          setData((data) => [...data, message.note]);
        }
      }),
    [conversationId, subscribe]
  );

  return {
    data,
    loading,
    add
  };
}
