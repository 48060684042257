import { NavLink } from "react-router-dom";
import * as React from "react";

const navLinkClassName =
  "transition duration-200 delay-100 hover:bg-cornflower-blue-400 hover:delay-100";
const navLinkActiveClassName = "bg-cornflower-blue-700";

export function LeftNavLink(props: {
  to: string;
  label?: string;
  icon?: React.ReactNode;
  selected?: boolean;
  enabled?: boolean;
  count?: number;
}) {
  const classes = props.selected ? navLinkActiveClassName : navLinkClassName;
  if (!props.enabled) return null;

  return (
    <NavLink to={props.to}>
      <div
        className={`flex flex-col items-center justify-center relative rounded-lg py-[8.5px] px-[5px] ${classes}`}
      >
        <div className="z-99 mt-2 flex-col items-center justify-center gap-1 mb-1 flex text-white">
          {props.icon}
        </div>
        {props.count != null && props.count > 0 && (
          <div className="absolute top-[8px] -right-[12px] bg-red-500 text-white rounded-full px-1 text-xs">
            {props.count > 99 ? "99+" : props.count}
          </div>
        )}
        <div style={{ fontSize: 14 }} className="mb-2">
          {props.label}
        </div>
      </div>
    </NavLink>
  );
}

export function LeftNavButton(props: {
  children: React.ReactNode;
  icon: React.ReactNode;
}) {
  const classes = navLinkClassName;
  return (
    <div className="cursor-pointer flex flex-col items-center justify-center px-3">
      <div
        className={`z-99 mt-2 flex-col items-center justify-center gap-1 mb-1 flex text-white`}
      >
        {props.icon}
      </div>
      <div style={{ fontSize: 14 }} className="mb-2">
        {props.children}
      </div>
    </div>
  );
}
