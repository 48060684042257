import React, { useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import useConversation from "@/hooks/useConversation";
import useUnreadMessages from "@/hooks/useUnreadMessages";
import useNotification from "@/hooks/useNotification";
import ConversationHeader from "./ConversationHeader";
import MessageList from "./MessageList";
import RightAside from "./RightAside/RightAside";
import useIsMobile from "@/hooks/useIsMobile";
import { is } from "date-fns/locale";

export default function ConversationDetail() {
  const { conversationId } = useParams<{ conversationId: string }>();

  const { conversation, conversationLoading, dispatch } = useConversation(
    parseInt(conversationId!)
  );

  const [showRightAside, setShowRightAside] = useState(false);

  const isMobile = useIsMobile();

  const unreadMessages = useUnreadMessages();
  const notification = useNotification();

  React.useEffect(() => {
    unreadMessages.setCurrentConversationId(parseInt(conversationId!));
    notification.setCurrentConversationId(parseInt(conversationId!));

    return () => {
      unreadMessages.setCurrentConversationId(undefined);
      notification.setCurrentConversationId(undefined);
    };
  }, [conversationId, notification, unreadMessages]);

  if (!conversation.id || conversationLoading) {
    return (
      <div className="flex h-screen justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  function renderMessageList() {
    if (isMobile && showRightAside) {
      return <div></div>;
    } else {
      return (
        <div className="conversation-container h-full w-full shrink-[1] min-w-0">
          <div className="h-full flex flex-col dark:bg-stone-300">
            <div className="conversation-header-wrapper flex-none bg-white dark:bg-stone-300 dark:text-white z-10  border-b-2 border-gray-200">
              <ConversationHeader conversation={conversation} />
            </div>
            <div className="message-list-wrapper flex-1 overflow-y-auto">
              <MessageList
                contactId={conversation.contact.id}
                conversation={conversation}
                updateTopic={(t) =>
                  dispatch({
                    type: "UPDATE_TOPIC",
                    topic: t
                  })
                }
              />
            </div>
          </div>
        </div>
      );
    }
  }

  function renderRightAside() {
    if (isMobile && !showRightAside) {
      return <div></div>;
    } else {
      return (
        <RightAside
          conversation={conversation}
          updateConversation={(c) => {
            dispatch({
              type: "SET_CONVERSATION",
              conversation: c
            });
          }}
        />
      );
    }
  }

  function renderDetailsText() {
    if (showRightAside) {
      return "Return to Conversation";
    }
    return "Conversation Details";
  }

  return (
    <div className="flex flex-col h-full text-sm">
      {/* mobile nav */}
      {isMobile && (
        <div className="flex items-center flex-none bg-white shadow h-12 px-4">
          <Link
            to="/conversations"
            className="h-12 w-8 flex items-center justify-center"
          >
            <i className="fa fa-angle-left"></i>
          </Link>
          <div className="font-semibold">{conversation.contact.name}</div>
          <div className="flex items-center ml-auto gap-2">
            <button
              className="h-12"
              onClick={() => {
                setShowRightAside(!showRightAside);
              }}
            >
              {renderDetailsText()}
            </button>
          </div>
        </div>
      )}
      <div className="conversation-detail flex-1 flex overflow-hidden bg-slate-gray-50">
        {/* conversation container */}
        {renderMessageList()}
        {/* right aside container */}
        {renderRightAside()}
      </div>
    </div>
  );
}
