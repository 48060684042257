import {
  Conversation,
  ConversationListItem,
  NotificationSettings
} from "@/types";
import React from "react";

interface Props {
  settings: NotificationSettings;
  updateSettings(updates: Partial<NotificationSettings>): void;

  setCurrentConversationId: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >;
  displayReassignmentNotification(
    conversation: ConversationListItem,
    previousAssignedUser: string,
    message: string,
    teamName: string
  ): void;
}

const NotificationContext = React.createContext<Props>({
  setCurrentConversationId() {},
  settings: {
    newConversations: false,
    newMessages: false,
    unassignWarning: false,
    textAlerts: false,
    conversationAssigned: false
  },
  updateSettings() {},
  displayReassignmentNotification() {}
});

export default NotificationContext;
