import React, { useEffect, useMemo, useRef, useState } from "react";
import useApi from "@/hooks/useApi";
import useProfile from "@/hooks/useProfile";
import useRoleListItems from "@/hooks/useRolesListItems";
import useStatus from "@/hooks/useStatus";
import useTranslations from "@/hooks/useTranslations";
// import useTheme from "@/hooks/useTheme";

import IconArrowForward1 from "@/icons/IconArrowForward1";
import IconSettingsGear from "@/icons/IconSettingsGear";
import User01 from "@velaro/velaro-shared/src/svg/stroke-icons/user-01.svg?react";
import Bell01 from "@velaro/velaro-shared/src/svg/stroke-icons/bell-01.svg?react";
import MoonStar from "@velaro/velaro-shared/src/svg/stroke-icons/moon-star.svg?react";

import OutlineBadge from "@velaro/velaro-shared/src/components/Badges/OutlineBadge";
import ToggleSwitch from "@velaro/velaro-shared/src/components/ToggleSwitch";
import StatusOption from "@velaro/velaro-shared/src/components/StatusColor";
import MenuLink from "./MenuLink";

interface Props {
  onClose(): void;
}

export default function DesktopProfileMenu({ onClose }: Props) {
  const { profile, setProfile } = useProfile();
  // const [profilePicture, setProfilePicture] = useState<Blob>();
  const [pictureUrl, setPictureUrl] = useState("");
  const profilePictureInput = useRef<HTMLInputElement>(null);
  const [showStatusPanel, setShowStatusPanel] = useState(false);

  const api = useApi();
  const { status, setStatus } = useStatus();
  const { getRoleNameById } = useRoleListItems();
  const translation = useTranslations();

  // const { theme, setTheme } = useTheme();

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPictureUrl(profile.picture);
  }, [profile.picture]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [onClose]);

  async function handleSubmit(picture: File) {
    const formData = new FormData();
    formData.append("name", profile.displayName);
    formData.append("phone", profile.phone);
    if (picture) formData.append("picture", picture);

    const resp = await api.messaging.postFormData("Profile/Update", formData);
    const data = await resp.json();

    setProfile(data);

    if (profilePictureInput.current) {
      profilePictureInput.current.value = "";
    }
  }

  const renderRoleBadge = (role: string, index: number) => {
    let roleName = role;
    const parsedNumber = parseInt(role);
    if (!isNaN(parsedNumber)) {
      roleName = getRoleNameById(parsedNumber);
    }

    return <OutlineBadge key={index} label={roleName} size="sm" />;
  };

  const statusColor = useMemo(() => {
    switch (status) {
      case "Available":
        return "success-500";
      case "Unavailable":
        return "sweet-orange-500";
      case "Offline":
        return "slate-500";
      default:
        return "sweet-orange-500";
    }
  }, [status]);

  return (
    <div
      ref={menuRef}
      className="absolute bottom-0 left-16 z-20 text-slate-gray-600 shadow border rounded bg-white w-64"
    >
      <div className="relative p-4">
        <div className="flex items-center mb-4">
          <img
            className="h-12 w-12 rounded-full border"
            src={pictureUrl}
            alt="Profile"
          />
          <div className="pl-3">
            <div className="font-semibold text-lg">{profile.displayName}</div>
            <div className="flex flex-wrap items-center">
              {profile.roles?.map(renderRoleBadge)}
            </div>
          </div>
        </div>

        <div className="relative w-full mb-4">
          {showStatusPanel && (
            <div className="absolute w-40 p-2 bg-white rounded shadow top-0 left-[calc(100%+10px)]">
              <StatusOption
                statusText={translation.key("Available")}
                bgColor="success-500"
                onClick={() => {
                  setStatus("Available");
                  setShowStatusPanel(false);
                }}
              />
              <StatusOption
                statusText={translation.key("Away")}
                bgColor="sweet-orange-500"
                onClick={() => {
                  setStatus("Unavailable");
                  setShowStatusPanel(false);
                }}
              />
              <StatusOption
                statusText={translation.key("Offline")}
                bgColor="slate-500"
                onClick={() => {
                  setStatus("Offline");
                  setShowStatusPanel(false);
                }}
              />
            </div>
          )}

          <div
            className="flex justify-between items-center py-2 cursor-pointer hover:bg-slate-gray-200"
            onClick={() => setShowStatusPanel(!showStatusPanel)}
          >
            <div className="flex items-center">
              <div className={`w-4 h-4 rounded-full bg-${statusColor}`}></div>
              <div className="ml-2">
                {status === "Unavailable"
                  ? translation.key("Away")
                  : translation.key(status)}
              </div>
            </div>
            <IconArrowForward1 className="w-5 h-5" />
          </div>
        </div>

        <hr className="border-gray-200" />

        <div className="whitespace-nowrap">
          <MenuLink
            to="/profile"
            onClose={onClose}
            icon={User01}
            label="profile_details"
          />

          <MenuLink
            to="#"
            onClose={onClose}
            icon={Bell01}
            label="notification_settings"
          />

          <div className="flex items-center h-12 my-4">
            <MoonStar className="h-6 w-6" />
            <span className="ml-2 flex-1">Dark Mode</span>
            <ToggleSwitch onClick={() => {}} label="" disabled={false} />
          </div>

          <hr className="border-gray-200" />
          <MenuLink
            to="/logout"
            onClose={onClose}
            icon={IconSettingsGear}
            label="log_out"
            className="flex items-center h-12 hover:bg-slate-gray-200 rounded pr-4 py-2 mt-4"
          />
        </div>
      </div>
    </div>
  );
}
