import { Conversation } from "@/types";
import React from "react";
import PastConversation from "./PastConversation";

interface Props {
  conversations: Conversation[];
  selectedConversationId?: number;
  updatePastConvo(pastConvo: Partial<Conversation>, convoId: number): void;
}

export default function PastConversations({
  conversations,
  selectedConversationId,
  updatePastConvo
}: Props) {
  return (
    <div className="w-full bg-slate-gray-100 flex-col justify-end items-center gap-6 inline-flex">
      {conversations.map((c) => (
        <PastConversation
          key={c.id}
          conversation={c}
          expanded={c.id == selectedConversationId}
          updatePastConvo={updatePastConvo}
        />
      ))}
    </div>
  );
}
