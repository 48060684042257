import * as React from "react";
import ResizableTextArea from "@/components/ResizableTextArea";
import useApi from "@/hooks/useApi";
import { Conversation } from "@/types";
import CannedResponseSelect from "./CannedResponseSelect";
import useCannedResponses from "@/hooks/useCannedResponses";
import FileDropHandler from "@/components/FileDropHandler";
import MessageFormAttachments from "./MessageFormAttachments";
import UploadButton from "./UploadButton";
import IconEmoji from "@/icons/IconEmoji";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import useScreenWidth from "@/hooks/useScreenWidth";
import useTheme from "@/hooks/useTheme";
import { throttle } from "lodash";
import { useCallback } from "react";
import SendMessageIcon from "@/icons/SendMessageIcon";
import FormstackButton from "./FormstackButton";
import useSubscriptions from "@/hooks/useSubscriptions";
import AIChatButton from "./AIChatButton";

interface Props {
  conversation: Conversation;
}

export default function MessageForm(props: Props) {
  const [message, setMessage] = React.useState("");
  const [attachments, setAttachments] = React.useState<File[]>([]);
  const [toastError, setToastError] = React.useState("");
  const [selectionStart, setSelectionStart] = React.useState(0);
  const [selectionEnd, setSelectionEnd] = React.useState(0);
  const { cannedResponses } = useCannedResponses();
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [showAiNotice, setShowAiNotice] = React.useState(false);
  const [loadingAiResponse, setLoadingAiResponse] = React.useState(false);
  const [firstDetectedEmojiClick, setFirstDetectedEmojiClick] =
    React.useState(false);

  const api = useApi();
  const subscription = useSubscriptions();
  const screenWidth = useScreenWidth();
  const { theme, setTheme } = useTheme();

  let emojiPickerStyling = "absolute bottom-28";
  if (screenWidth < 768) {
    emojiPickerStyling = "absolute bottom-28 inset-x-0";
  }

  const cannedResponseToken = React.useMemo(() => {
    if (message.length === 0) {
      return "";
    }

    if (message[0] != "/") {
      return "";
    }

    return message;
  }, [message]);

  const showCannedResponses = React.useMemo(
    () => !!cannedResponseToken,
    [cannedResponseToken]
  );

  const sendMessage = React.useCallback(
    async (text: string, attachments: File[]) => {
      if (text) {
        text = text.trim();
      }

      setMessage("");
      setAttachments([]);
      setShowAiNotice(false);

      if (!text && !attachments.length) {
        return;
      }

      let result: Response;

      const payload = {
        conversationId: props.conversation.id,
        text
      };

      if (attachments.length === 0) {
        result = await api.messaging.post("Messages", payload);
      } else {
        const formData = new FormData();
        formData.append("payload", JSON.stringify(payload));

        for (const file of attachments) {
          formData.append("files", file);
        }

        result = await api.messaging.postFormData("Messages", formData);
      }

      if (result.status === 400) {
        setToastError(await result.text());
      }
    },
    [api.messaging, props.conversation.id]
  );

  const focusTextArea = React.useCallback(() => {
    const el = document.getElementById("txt-message");

    if (el) {
      el.focus();
    }
  }, []);

  const isTyping = useCallback(async () => {
    await api.messaging.post(
      `Agent/Activity/AgentTyping/${props.conversation.id}`
    );
  }, [api.messaging, props.conversation.id]);

  const throttledTyping = React.useMemo(
    () => throttle(isTyping, 1000),
    [isTyping]
  );

  const clearUnread = React.useCallback(async () => {
    await api.messaging.post("UnreadMessages/Clear", {
      conversationId: props.conversation.id
    });
  }, [api.messaging, props.conversation.id]);

  return (
    <div className="relative mt-4">
      <div className={loadingAiResponse == true ? "aianimation" : ""}>
        <div
          className={
            loadingAiResponse == true
              ? "aianimationcontent"
              : "border border-slate-gray-200 rounded-md "
          }
        >
          {toastError && (
            <div className="border-t-2 dark:border-stone-200">
              <div className="p-5 flex items-center justify-between">
                <div>
                  <div>
                    <strong>Error</strong>
                  </div>
                  <div className="text-crimson-red-500">{toastError}</div>
                </div>
                <i
                  title="Dismiss"
                  onClick={() => setToastError("")}
                  className="fa fa-times fa-xl cursor-pointer"
                ></i>
              </div>
            </div>
          )}
          {showCannedResponses && (
            <CannedResponseSelect
              token={cannedResponseToken}
              cannedResponses={cannedResponses}
              onSelect={(cannedResponse) => {
                setMessage(cannedResponse.messageText);
                focusTextArea();
              }}
            />
          )}

          <div className=" bg-slate-gray-50 rounded-lg border border-slate-gray-100 gap-2">
            <div className="px-3 pt-3">
              <FileDropHandler
                onDrop={(files) => {
                  setAttachments((s) => [...s, ...files]);
                }}
              />
              <MessageFormAttachments
                attachments={attachments}
                onUpdate={(attachments) => setAttachments(attachments)}
              />
              <div className="relative">
                <ResizableTextArea
                  id="txt-message"
                  className="bg-white p-3 h-10 rounded-lg pr-12 text-sm dark:bg-stone-500 dark:text-white"
                  autoFocus
                  onFocusCapture={clearUnread}
                  value={message}
                  placeholder="Type a message or start with '/' to select a canned response."
                  onChange={(e) => {
                    setMessage(e.currentTarget.value);
                    throttledTyping();
                  }}
                  onKeyDown={(e) => {
                    if (showCannedResponses) {
                      if (
                        e.key === "ArrowUp" ||
                        e.key === "ArrowDown" ||
                        e.key === "Enter"
                      ) {
                        e.preventDefault();
                      }
                    } else if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      sendMessage(message, attachments);
                    }
                  }}
                  onKeyUp={(e) => {
                    setSelectionStart(e.currentTarget.selectionStart);
                    setSelectionEnd(e.currentTarget.selectionEnd);
                  }}
                  onClick={(e) => {
                    setSelectionStart(e.currentTarget.selectionStart);
                    setSelectionEnd(e.currentTarget.selectionEnd);
                  }}
                  onFocus={(e) => {
                    setSelectionStart(e.currentTarget.selectionStart);
                    setSelectionEnd(e.currentTarget.selectionEnd);
                  }}
                />
                <button
                  className="absolute top-2 right-2 text-xs h-6 w-6 rounded-full flex items-center justify-center bg-slate-gray-500"
                  onClick={() => {
                    sendMessage(message, attachments);
                  }}
                >
                  <SendMessageIcon />
                </button>
              </div>
            </div>
            <div className="message-form-actions px-3 pb-3 flex items-end">
              <div className="text-slate-gray-400 flex items-center">
                <UploadButton
                  onAddAttachments={(files) => {
                    setAttachments((attachments) => [...attachments, ...files]);
                  }}
                />
                <button
                  className="h-8 w-8 flex items-center justify-center"
                  onClick={(e) => {
                    setShowEmojiPicker(!showEmojiPicker);
                    e.stopPropagation();
                  }}
                >
                  <IconEmoji />
                </button>
                {showEmojiPicker && (
                  <div className={emojiPickerStyling}>
                    <Picker
                      data={data}
                      theme={theme}
                      onEmojiSelect={(emoji: { native: string }) => {
                        setMessage((oldState) => {
                          console.log(oldState);
                          console.log(oldState.slice(0, selectionStart));
                          console.log(oldState.slice(selectionEnd));
                          return (
                            oldState.slice(0, selectionStart) +
                            emoji.native +
                            oldState.slice(selectionEnd)
                          );
                        });
                        // Each emoji technically adds 2 characters to the message length
                        // We manually increment our selectionStart and selectionEnd to make sure emojis are added to the end of the current string of emojis
                        // If we didnt do this step, emojis would always be added to the front of the string
                        setSelectionStart(selectionStart + 2);
                        setSelectionEnd(selectionEnd + 2);
                      }}
                      onClickOutside={() =>
                        setShowEmojiPicker(!showEmojiPicker)
                      }
                      previewPosition={"none"}
                    />
                  </div>
                )}
                <AIChatButton
                  conversation={props.conversation}
                  message={message}
                  setLoadingAiResponse={setLoadingAiResponse}
                  setShowAiNotice={setShowAiNotice}
                  setMessage={setMessage}
                />
                {subscription.subscription.enableFormstack == true && (
                  <FormstackButton conversation={props.conversation} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAiNotice && (
        <div className="pt-4 text-slate-gray-500 animate-fade-in-quick">
          Generated with AI, please double-check its responses before sharing.
        </div>
      )}
    </div>
  );
}
