/* eslint-disable @typescript-eslint/no-namespace */

import { BadgeColor } from "@velaro/velaro-shared/src/components/types";
import { uniqueId } from "lodash";

export type Theme = "light" | "dark";

export interface MessageUser {
  id: number;
  userName: string;
  name: string;
  picture: string;
  isDeleted: boolean;
}

export interface MessageContact {
  displayName: string | undefined;
  id: number;
  email: string;
  name: string;
  picture: string;
}

export interface MessageBot {
  id: number;
  name: string;
  picture: string;
}

export interface Message {
  id: number;
  conversationId: number;
  timestamp: string;
  text: string;
  attachments: MessageAttachment[];
  contact?: MessageContact;
  user?: MessageUser;
  bot?: MessageBot;
  isAutoResponse: boolean;
  type: "user" | "bot" | "contact" | "system" | "link";
  color?: string;
  link?: string;
}

export interface MessageAttachment {
  id: number;
  type: string;
  url: string;
  fileName: string;
  contentType: string;
}

export interface Contact {
  id: number;
  picture: string;
  name: string;
  firstName?: string;
  lastName?: string;
  fallbackName?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  city?: string;
  state?: string;
  country?: string;
  address?: string;
  zipCode?: string;
  jobTitle?: string;
  company?: string;
  companyUrl?: string;
  uniqueId?: string;
  displayName: string;
  lastActivity: string;
  facebookProfileUrl?: string;
  whatsAppProfileUrl?: string;
  linkedInProfileUrl?: string;
}

export const conversationSources = [
  "Twitter",
  "Facebook",
  "Web",
  "TwilioSms",
  "Email",
  "WhatsApp"
];

export type ConversationSource = (typeof conversationSources)[number];

export interface Conversation {
  id: number;
  topic: string;
  assignedUserId?: number;
  groupId: number | undefined;
  groupName: string;
  startTimestamp: string;
  endTimestamp: string;
  firstResponseTimestamp: string;
  automationJobId?: number;
  automationJobStatus?: AutomationJobStatus;
  assignedUser?: {
    id: number;
    name?: string;
    userName: string;
    picture?: string;
  };
  contact: Contact;
  source: ConversationSource;
  status: ConversationStatus;
  sentiment?: SentimentType;
  resolvedBy?: string;
  resolvedReason?: string;
}

export interface InboxFilters {
  assignment: AssignmentStatus[];
  status: ConversationStatus[];
  channel: ConversationSource[];
  agent: number[];
  team: number[];
}

export interface AssignmentFilter {
  assignments: string[];
}

export interface StatusFilter {
  statuses: string[];
}

export interface ChannelFilter {
  channels: ConversationSource[];
}

export interface AgentFilter {
  agents: string[];
}

export interface TeamFilter {
  teams: string[];
}

export interface ConversationListItem {
  id: number;
  groupId: number | undefined;
  name: string;
  pic: string;
  lastMessageText: string;
  lastMessageTimestamp: string;
  contactId: number;
  source: ConversationSource;
  assignedUserId?: number;
  automationJobId?: number;
  automationJobStatus?: AutomationJobStatus;
  startTimestamp: string;
  firstResponseTimestamp: string;
  unassignWarningTimestamp: string;
  unassignTime: number;
  conversationStatus: ConversationStatus;
  agentName: string;
  assignmentStatus: AssignmentStatus;
  topic: string;
}

export const defaultProfile: Profile = {
  id: 0,
  email: "",
  picture: "",
  displayName: "",
  firstName: "",
  lastName: "",
  language: "en",
  country: "",
  timezone: "",
  phone: "",
  teamIds: [],
  skillIds: [],
  roles: [],
  soundUrl: "",
  soundEnabled: true,
  newConversationSound: "new_message_1.mp3",
  newMessageSound: "new_message_1.mp3",
  chatTranslationsEnabled: false
};

export interface Profile {
  [key: string]: any;
  id: number;
  email: string;
  picture: string;
  displayName: string;
  firstName: string;
  lastName: string;
  language: string;
  phone: string;
  country: string;
  timezone: string;
  teamIds: number[];
  skillIds: number[];
  roles: string[];
  soundUrl: string;
  soundEnabled: boolean;
  newConversationSound: string;
  newMessageSound: string;
  chatTranslationsEnabled: boolean;
}

export interface TeamListItem {
  id: number;
  name: string;
  autoRoute: boolean;
  routingType: string;
  isDefault: boolean;
  autoUnassign: boolean;
  unassignWarningTime: number;
  unassignTime: number;
}

export interface SkillListItem {
  id: number;
  name: string;
  autoRoute: boolean;
  routingType: string;
  isDefault: boolean;
  autoUnassign: boolean;
  unassignWarningTime: number;
  unassignTime: number;
}

export interface FormListItem {
  id: string;
  name: string;
  totalSubmissions: number;
  url: string;
  deleted: string;
}

export interface FormList {
  forms: FormListItem[];
}

export interface CannedResponse {
  id: number;
  groupId: number | undefined;
  messageText: string;
  shortCode: string;
}

export type AutomationJobStatus = "in_progress" | "done";

export type AssignmentStatus = "Assigned" | "Incoming" | "BotChat";

export type ConversationStatus = "Open" | "Resolved";

export type SentimentType = "positive" | "negative" | "neutral";

export type UserStatusType = "Available" | "Unavailable" | "Offline";

export interface NotificationSettings {
  newConversations: boolean;
  newMessages: boolean;
  unassignWarning: boolean;
  textAlerts: boolean;
  conversationAssigned: boolean;
}

export interface ToastAlertSettings {
  stickyAlerts: boolean;
  stickyDuration: number;
}

export interface Agent {
  id: number;
  userName: string;
  displayName: string;
  picture: string;
  email: string;
  status: UserStatusType;
}

export namespace HubMessages {
  export interface ConversationResolved {
    conversation: ConversationListItem;
  }

  export interface ConversationReopened {
    conversation: ConversationListItem;
  }

  export interface UserListItem {
    id: number;
    userName: string;
    email: string;
    name: string;
    picture: string;
  }

  export interface ConversationAssigned {
    conversation: ConversationListItem;
    user: UserListItem;
    assigningUser: UserListItem;
    previousAssignedUserId: number | undefined;
  }

  export interface ConversationUnassigned {
    unassignedUserId: number;
    conversation: ConversationListItem;
  }

  export interface ConversationCreated {
    conversation: ConversationListItem;
  }

  export interface ReceiveMessage {
    assignedUserId: number;
    assignedBotId: number;
    conversationId: number;
    message: Message;
  }

  export interface ReceiveInvitation {
    conversation: ConversationListItem;
  }

  export interface DeleteAttachment {
    conversationId: number;
    messageId: number;
    attachmentId: number;
  }

  export interface AssignGroup {
    conversationId: number;
    groupId: number | undefined;
  }

  export interface ContactNoteAdded {
    contactId: number;
    note: Note;
  }

  export interface ContactTagsUpdated {
    contactId: number;
    allTags: Tag[];
    selectedTags: Tag[];
  }

  export interface ConversationNoteAdded {
    conversationId: number;
    note: Note;
  }

  export interface ConversationTagsUpdated {
    conversationId: number;
    allTags: Tag[];
    selectedTags: Tag[];
  }

  export interface UnassignWarning {
    conversation: ConversationListItem;
    unassignTime: string;
  }

  export interface AutomationDone {
    conversation: ConversationListItem;
  }

  export interface IsTyping {
    id: number;
  }

  export interface SkillAssigned {
    conversation: ConversationListItem;
    skillId: number;
    assigningUser: UserListItem;
  }

  export interface TeamAssigned {
    conversation: ConversationListItem;
    teamId: number;
    assigningUser: UserListItem;
  }
}

export type AppEvent = "connected" | "reconnected";

export const inboxTypes = ["mine", "all"] as const;

export type InboxTabType = (typeof inboxTypes)[number];

export interface CrmConfigDetail {
  id: number;
  displayName: string;
  type: CrmType;
}

export type CrmType = "NetSuite" | "Salesforce";

export type InboxSortBy =
  | "newest"
  | "oldest"
  | "longest"
  | "priority"
  | "agent";

export type InboxGroupBy = "none" | "channel" | "status" | "agent";

export interface InboxTab {
  count: number;
  conversations: ConversationListItem[];
}

export interface InboxTabs {
  mine: InboxTab;
  //incoming: InboxTab;
  all: InboxTab;
}

export interface InboxContent {
  tabs: InboxTabs;
}

export interface InboxSettings {
  selectedTab: InboxTabType;
  filters: InboxFilters;
  sortBy: InboxSortBy;
  groupBy: InboxGroupBy;
  searchQuery: string;
}

export interface Note {
  id: number;
  text: string;
  user: string;
  date: string;
}

export interface Tag {
  id: number;
  name: string;
  color: BadgeColor;
}

export interface Attribute {
  id: number;
  name: string;
  value: string;
}

export interface SurveySubmission {
  id: number;
  name: string;
  type: string;
  answers: SurveyAnswer[];
}

export interface SurveyAnswer {
  id: number;
  question: string;
  answer: string;
}

export interface SoundModel {
  label: string;
  fileName: string;
}

export type SoundType = "newConversation" | "newMessage";
