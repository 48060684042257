import React, { useCallback, useState } from "react";
import useApi from "./useApi";

export default function useVersion() {
  const api = useApi();
  const [version, setVersion] = useState(null);
  const [showReloadModal, setShowReloadModal] = useState(false);

  function resetModal() {
    setShowReloadModal(false);
  }
  const getVersion = useCallback(async () => {
    const response = await api.messaging.get("version");
    const newVersion = await response.json();
    if (version == null) {
      setVersion(newVersion);
    } else if (version != newVersion) {
      setShowReloadModal(true);
    }
  }, [api.messaging, version]);

  React.useEffect(() => {
    if (version == null) {
      getVersion();
    }
    //periodically ping the server to check for a new version
    const interval = setInterval(getVersion, 1000 * 30);
    return () => clearInterval(interval);
  }, [api.messaging, getVersion, version]);

  return { showReloadModal, resetModal };
}
