import { Attribute } from "@/types";
import { useEffect, useState } from "react";
import useApi from "./useApi";

export default function useConversationAttributes(conversationId: number) {
  const [conversationAttributes, setConversationAttributes] = useState<
    Attribute[]
  >([]);
  const api = useApi();

  useEffect(() => {
    async function fetchAll() {
      const resp = await api.messaging.get(
        `ConversationAttributes?conversationId=${conversationId}`
      );
      setConversationAttributes(await resp.json());
    }

    fetchAll();
  }, [api.messaging, conversationId]);

  return { conversationAttributes };
}
