import { Tag } from "@/types";
import { useEffect, useState } from "react";
import useApi from "./useApi";
import useSubscribe from "./useSubscribe";
import { set } from "lodash";

export default function useConversationTags(conversationId: number) {
  const [all, setAll] = useState<Tag[]>([]);
  const [selected, setSelected] = useState<Tag[]>([]);
  const [saving, setSaving] = useState(false);
  const api = useApi();
  const subscribe = useSubscribe();

  useEffect(() => {
    async function fetchAll() {
      const resp = await api.messaging.get("Tags/Conversations");
      setAll(await resp.json());
    }

    fetchAll();
  }, [api.messaging]);

  useEffect(() => {
    async function fetchSelected() {
      const resp = await api.messaging.get(
        `TagAssociation/Conversation/Selected?conversationId=${conversationId}`
      );
      setSelected(await resp.json());
    }

    fetchSelected();
  }, [api.messaging, conversationId]);

  async function create(label: string) {
    const resp = await api.messaging.post("Tags/Conversation/Create", {
      name: label
    });

    const tag = await resp.json();
    setAll([...all, tag]);
    return tag;
  }

  async function update(tag: Tag) {
    const resp = await api.messaging.put(`Tags/Conversation/Update`, {
      id: tag.id,
      name: tag.name,
      color: tag.color
    });

    const updatedTag = await resp.json();
    setAll(all.map((t) => (t.id === updatedTag.id ? updatedTag : t)));
    return updatedTag;
  }

  function save(tags: Tag[]) {
    setSaving(true);
    setSelected([...selected, ...tags]);
    api.messaging.post("TagAssociation/Conversation/Associate", {
      conversationId,
      conversationTags: tags
    });
    setSaving(false);
  }

  function remove(tag: Tag) {
    setSelected(selected.filter((t) => t.id !== tag.id));
    api.messaging.post("TagAssociation/Conversation/Remove", {
      conversationId,
      tagId: tag.id
    });
  }

  useEffect(
    () =>
      subscribe("conversation-tags-updated", (message) => {
        setAll(message.allTags);

        if (message.conversationId === conversationId) {
          setSelected(message.selectedTags);
        }
      }),
    [conversationId, subscribe]
  );

  return { all, selected, save, remove, create, update, saving };
}
