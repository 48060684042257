import { ConversationSource } from "@/types";
import React from "react";
import MessageGroupItem from "./MessageGroupItem";
import usePastMessages from "@/hooks/usePastMessages";

interface Props {
  conversationId: number;
  contactId: number;
  conversationSource: ConversationSource;
}

export default function PastConversationMessages(props: Props) {
  const { groupedMessages } = usePastMessages(props.conversationId);

  return (
    <div>
      {groupedMessages.map((item, index) => {
        return (
          <MessageGroupItem
            key={index}
            contactId={props.contactId}
            item={item}
            conversationSource={props.conversationSource}
            onImageLoad={() => {}}
          />
        );
      })}
    </div>
  );
}
