import React from "react";

interface Props {
  totalCount: number;
  getCount(conversationId: number): number;
  setCurrentConversationId(conversationId: number | undefined): void;
}

const UnreadMessagesContext = React.createContext<Props>({
  totalCount: 0,
  getCount() {
    return 0;
  },
  setCurrentConversationId() {}
});

export default UnreadMessagesContext;
