import AppLoading from "@/components/AppLoading";
import useConversation from "@/hooks/useConversation";
import usePastConversations from "@/hooks/usePastConversations";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PastConversations from "../Conversations/PastConversations";
import RightAside from "../Conversations/RightAside/RightAside";

export default function TranscriptView() {
  const {
    contactId: contactIdParam,
    conversationId: conversationIdParam,
  } = useParams<{
    contactId: string;
    conversationId: string;
  }>();
  const contactId = Number(contactIdParam);
  const conversationId = Number(conversationIdParam);
  const navigate = useNavigate();
  const { pastConversations, updatePastConversation } = usePastConversations(
    contactId
  );
  const { conversation, conversationLoading, dispatch } = useConversation(
    conversationId
  );

  if (conversationLoading) {
    return <AppLoading />;
  }

  if (isNaN(contactId) || isNaN(conversation.id)) {
    return <AppLoading />;
  }

  return (
    <div className="flex flex-col h-full text-sm">
      <div className="conversation-detail flex-1 flex overflow-hidden">
        {/* conversation container */}
        <div className="conversation-container h-full flex-auto min-w-0">
          <div className="h-full flex flex-col dark:bg-stone-300">
            <div className="conversation-header-wrapper flex-none bg-white dark:bg-stone-300 dark:text-white z-10 border border-gray-200">
              <div className="conversation-header flex h-16 items-center">
                <button
                  onClick={() => navigate("/dashboard")}
                  className="m-4 btn-steel"
                >
                  Back
                </button>
              </div>
            </div>
            <div className="message-list-wrapper flex-1 overflow-y-auto">
              <PastConversations
                conversations={pastConversations}
                updatePastConvo={updatePastConversation}
                selectedConversationId={conversationId}
              />
            </div>
          </div>
        </div>
        <div className="right-aside-container w-96 flex-none overflow-auto">
          <RightAside
            conversation={conversation}
            updateConversation={(c) => {
              dispatch({
                type: "SET_CONVERSATION",
                conversation: c,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}
