import React, { useState } from "react";
import Inbox from "./Inbox";
import { Outlet, useMatch } from "react-router-dom";
import ConversationDetail from "./ConversationDetail";
import useScreenWidth from "@/hooks/useScreenWidth";

function MobileConversationsPage() {
  const match = useMatch("/conversations/*");
  const conversationId = match?.params["*"];

  return (
    <div className="flex h-full">
      <div className="flex-1 min-w-0 h-full">
        {!conversationId && (
          <div className="h-full bg-white">
            <Inbox />
          </div>
        )}
        {conversationId && <ConversationDetail />}
      </div>
    </div>
  );
}

function DesktopConversationsPage() {
  const [sideMenuexpanded, setSideMenuExpanded] = React.useState(true);
  let iconClassName =
    "transition fa-solid fa-arrow-left w-6 h-6 items-center justify-center bg-[#5C82B5] rounded-full absolute left-[443px] mt-[20px] flex z-0 text-white";
  if (!sideMenuexpanded) {
    iconClassName =
      "transition fa-solid fa-arrow-left w-6 h-6 items-center justify-center bg-[#5C82B5] rounded-full ml-[-12px] mt-[20px] flex z-0 text-white fa-rotate-180";
  }

  return (
    <div className="flex h-full max-w-full">
      {sideMenuexpanded && <Inbox />}
      <div className="flex-1 min-w-0 h-full">
        <Outlet />
      </div>
    </div>
  );
}

export default function ConversationsPage() {
  const screenWidth = useScreenWidth();

  if (screenWidth < 768) {
    return <MobileConversationsPage />;
  }

  return <DesktopConversationsPage />;
}
